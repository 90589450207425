import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { styled } from "styled-components";
import { ContactInitial, ContactType } from "../../types/dbTypes";
import { COLORS } from "../../utils/colors";
import { ICONS } from "../../utils/requireIcons";
import { IMAGES } from "../../utils/requireImages";
import Button from "../Button/Button";
import Input from "../Input/Input";
import MuskInput from "../MuskInput/MuskInput";
import Text from "../Text/Text";

const Contact = () => {
    const [contact, setContact] = useState<ContactType>(ContactInitial);

    const onChangeInput = (value: string, keyName: string) => {
        setContact((d) => ({ ...d, [keyName]: value }));
    };
    const sendData = `Новое сообщение%0A Имя: ${contact.name}%0A Номер телефона: ${contact.phone}%0A Почта: ${contact.email}%0A Элемент: ${contact.item}%0A Сообщение: ${contact.message}`;

    const onSendContactData = async () => {
        if (contact.phone) {
            await axios({
                method: "post",
                url: `https://api.telegram.org/bot6804287688:AAETX_b7adxrUT0MVjQXrZuQ4Y-8cSDnxKo/sendMessage?chat_id=-1002079577865&text=${sendData}`,
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    message.success(
                        "Ваша информация отправлена ​​и мы свяжемся с вами в ближайшее время"
                    );
                    setContact(ContactInitial);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            message.warning("Пожалуйста, введите свой номер телефона");
            return;
        }
    };

    return (
        <ContactContainer id="contact">
            <div className="Contact_title">
                <img
                    className="Contact_title_line"
                    src={ICONS.lineSmall}
                    alt=""
                />
                <Text children="Свяжитесь" fontSize="fourty" />
                <Text
                    children=" с нами"
                    fontSize="fourty"
                    color={COLORS.yellow}
                />
            </div>
            <div className="contact_content">
                <div className="contact_left">
                    <img
                        className="contact_leftImg"
                        src={IMAGES.firGirl}
                        alt=""
                    />
                    <Text
                        children="Наши специалисты, готовы ответить на ваши вопросы по номеру :"
                        fontSize="fourteen"
                        fontWeight="400"
                        margin="15px 0 0 0"
                        lineHeight="22px"
                    />
                    <a href="tel:+998 (91) 710 14 14">+998 91 710 14 14 .</a>
                    <Text
                        fontSize="fourteen"
                        fontWeight="400"
                        margin="15px 0 0 0"
                        children="Либо вы можете оставить нам заявку и наши менеджеры свяжутся с вами."
                    />
                </div>
                <div className="contact_right">
                    <div className="contact_rowInputs">
                        <div>
                            <Text
                                children="Ваше имя"
                                fontSize="fourteen"
                                margin="20px 0 6px 5px"
                                fontWeight="400"
                            />
                            <Input
                                onChange={(e) => onChangeInput(e, "name")}
                                width="250px"
                                value={contact.name}
                            />
                        </div>
                        <div>
                            <Text
                                children="Номер телефона"
                                fontSize="fourteen"
                                margin="20px 0 6px 5px"
                                fontWeight="400"
                            />
                            <MuskInput
                                onChange={(e) =>
                                    onChangeInput(e.target.value, "phone")
                                }
                                width="250px"
                                value={contact.phone}
                            />
                        </div>
                    </div>
                    <Text
                        children="Электронная почта"
                        fontSize="fourteen"
                        margin="20px 0 6px 5px"
                        fontWeight="400"
                    />
                    <Input
                        onChange={(e) => onChangeInput(e, "email")}
                        width="250px"
                        value={contact.email}
                    />
                    <Text
                        children="Предмет"
                        fontSize="fourteen"
                        margin="20px 0 6px 5px"
                        fontWeight="400"
                    />
                    <Input
                        onChange={(e) => onChangeInput(e, "item")}
                        width="250px"
                        value={contact.item}
                    />
                    <Text
                        children="Ваше сообщение"
                        fontSize="fourteen"
                        margin="20px 0 6px 5px"
                        fontWeight="400"
                    />
                    <textarea
                        className="message"
                        onChange={(e) =>
                            onChangeInput(e.target.value, "message")
                        }
                        value={contact.message}
                    />
                    <br />
                    <Button
                        children={"Отправлять"}
                        width={"280px"}
                        margin="20px 0 0 0"
                        backColor="#1f1f1f"
                        onPress={onSendContactData}
                    />
                </div>
            </div>
        </ContactContainer>
    );
};

export default Contact;

const ContactContainer = styled.div`
    padding: 50px 5%;

    .Contact_title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        .Contact_title_line {
            height: 40px;
            margin: 0 10px 0 0;
        }
    }

    .contact_content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 40px;

        .contact_left {
            width: 25%;
            padding: 20px 12px;
            background-color: #1f1f1f;
            box-shadow: 0px -3px 20px #5b5a5a;
            border-radius: 5px;

            .contact_leftImg {
                width: 100%;
            }
        }

        .contact_right {
            width: 72%;
            background-color: #1f1f1f;
            box-shadow: 0px -3px 20px #5b5a5a;
            padding: 20px 4%;
            border-radius: 5px;

            .message {
                width: 250px;
                height: 100px;
                padding: 10px;
                background-color: #1f1f1f;
                border-radius: 7px;
                outline: none;
                color: #fff;
                border: none;
                box-shadow: 0 2px 5px -2px rgba(144, 144, 144, 0.8);
                resize: none;
            }

            .contact_rowInputs {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    @media (min-width: 120px) {
        .contact_content {
            .contact_left {
                width: 100%;
            }
            .contact_right {
                width: 100%;
                text-align: center;
                .contact_rowInputs {
                    gap: 1px;
                    justify-content: center;
                }
            }
        }
    }

    @media (min-width: 576px) {
        .contact_content {
            .contact_right {
                text-align: start;
                .contact_rowInputs {
                    gap: 20px;
                    justify-content: start;
                }
            }
        }
    }
    @media (min-width: 776px) {
        .contact_content {
            .contact_left {
                width: 25%;
            }
            .contact_right {
                width: 72%;
                .contact_rowInputs {
                    gap: 20px;
                }
            }
        }
    }
`;
