export type SubsType = {
    title: string;
    sale?: string;
    details: string[];
    details2: string[];
};

export type ContactType = {
    name: string;
    email: string;
    phone: string;
    message: string;
    item: string;
};
export const ContactInitial = {
    name: "",
    email: "",
    phone: "",
    message: "",
    item: "",
};

export type MembershipVideoType = {
    video: string;
    image: string;
    title: string;
    type: string;
    views: string;
};
export type TrainersType = {
    name: string;
    image: string;
    job: string;
    username: string;
    instaLink: string;
    tgLink: string;
};

export type ReviewType = {
    name: string;
    image: string;
    job: string;
    comment: string;
    username: string;
    instaLink: string;
    tgLink: string;
};
