import { Clusterer, Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { ICONS } from "../../utils/requireIcons";
import Text from "../Text/Text";

const Location = () => {
    return (
        <LocationContent id="location">
            <div className="Location_title">
                <img className="Location_line" src={ICONS.lineSmall} alt="" />
                <Text children="Наше" fontSize="fourty" />
                <Text
                    children="Местоположение"
                    fontSize="fourty"
                    color={COLORS.yellow}
                    margin="0 0 0 15px"
                />
            </div>
            <YMaps>
                <div className="map_box">
                    <Map
                        className="map"
                        defaultState={{
                            center: [39.682211, 66.927567],
                            zoom: 13,
                            controls: ["fullscreenControl"],
                        }}
                        modules={["control.FullscreenControl"]}
                    >
                        <Clusterer
                            options={{
                                preset: "islands#glyphIcon",
                                groupByCoordinates: false,
                            }}
                        >
                            <Placemark
                                modules={[
                                    "geoObject.addon.balloon",
                                    "geoObject.addon.hint",
                                ]}
                                options={{
                                    openBalloonOnClick: true,
                                    preset: "islands#schoolIcon",
                                    iconColor: "red",
                                    cursor: "pointer",
                                }}
                                geometry={[39.682062, 66.926438]}
                            />
                        </Clusterer>
                    </Map>
                </div>
            </YMaps>
        </LocationContent>
    );
};

export default Location;

const LocationContent = styled.div`
    padding: 20px 5% 50px 5%;

    .Location_title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px 0;

        .Location_line {
            height: 40px;
            margin: 0 10px 0 0;
        }
    }

    .map_box {
        width: 100%;
        height: 400px;
        border-radius: 5px;
        overflow: hidden;

        .map {
            width: 100%;
            height: 100%;
        }
    }
`;
