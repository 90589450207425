import React from "react";
import { styled } from "styled-components";

interface Props {
    children?: any;
    onPress?: () => void;
    backColor?: string;
    color?: string;
    margin?: string;
    padding?: string;
    cursor?: string;
    border?: string;
    fontSize?: string;
    width?: string;
    className?: string;
}

const Button: React.FC<Props> = ({
    backColor,
    border,
    children,
    color,
    cursor,
    margin,
    onPress,
    padding,
    fontSize,
    width,
    className,
}) => {
    return (
        <ButtonContainer
            className={className}
            style={{
                backgroundColor: backColor,
                border: border,
                color: color,
                cursor: cursor,
                margin: margin,
                padding: padding,
                fontSize: fontSize,
                width: width,
            }}
            onClick={onPress}
        >
            {children}
        </ButtonContainer>
    );
};

export default Button;

const ButtonContainer = styled.button`
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: 1px solid #3c3c3d;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
`;
