import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { ICONS } from "../../utils/requireIcons";
import { IMAGES } from "../../utils/requireImages";
import Text from "../Text/Text";

const Footer = () => {
    return (
        <FooterContainer>
            <div className="footer_left">
                <img src={IMAGES.footer} alt="" />
            </div>
            <div className="footer_right">
                <div className="footer_category">
                    <img
                        className="footer_image"
                        src={ICONS.lineLarge}
                        alt=""
                    />
                    <div>
                        <div className="footer_title">
                            <Text
                                children="Получите тело своей мечты"
                                fontSize="fourty"
                            />
                            <Text
                                children="вместе с нами!"
                                fontSize="fourty"
                                color={COLORS.yellow}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer_links">
                    <div className="footer_linksOne">
                        <a href="#tickets">Абонементы</a>
                        <a href="#location">Местоположение</a>
                        <a href="#review">Обзор</a>
                    </div>
                    <div className="footer_linksTwo">
                        <a href="#contact">Связаться с нами</a>
                        <a href="#comments">Отзывы</a>
                    </div>
                    <div className="footer_linksThree">
                        <a href="tel:+998 (91) 710 14 14">+998 91 710 14 14</a>
                        <Text
                            fontSize="sixteen"
                            color={COLORS.white}
                            fontWeight="400"
                            children="Наш Адрес: г. Cамарканд, улица Ибн Холдуна, дом 5(
                            Ориентир: Вокзал, напротив 48 школы)"
                        />
                    </div>
                </div>
            </div>
        </FooterContainer>
    );
};

export default Footer;

const FooterContainer = styled.div`
    padding: 50px 5%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .footer_left {
        width: 400px;
        img {
            width: 100%;
        }
    }

    .footer_category {
        display: flex;
        gap: 20px;

        .footer_title {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        .footer_text {
            width: 60%;
        }

        img {
            height: 110px;
        }
    }

    .footer_links {
        display: flex;
        /* justify-content: space-between; */
        margin-top: 50px;
        gap: 30px;
        flex-wrap: wrap;
        width: 100%;

        .footer_linksOne {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 31%;
        }
        .footer_linksTwo {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 31%;
        }
        .footer_linksThree {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 31%;
        }

        a {
            color: #fff;
            text-decoration: none;
            font-size: 16px;
            font-weight: 400;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
        .footer_left {
            width: 300px;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
        .footer_category {
            .footer_text {
                width: 90%;
            }
        }
        .footer_links {
            .footer_linksOne {
                width: 100%;
            }
            .footer_linksTwo {
                width: 100%;
            }
            .footer_linksThree {
                width: 100%;
            }
        }
    }
`;
