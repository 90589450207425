import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { styled } from "styled-components";
import { ICONS } from "../../utils/requireIcons";
import Button from "../Button/Button";
import { IoMdMenu } from "react-icons/io";
import { COLORS } from "../../utils/colors";
import { IoMdClose } from "react-icons/io";

const Header = () => {
    const navigation = useNavigate();
    const [scroll, isScroll] = useState(false);
    const [mediaMenu, setMediaMenu] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                isScroll(true);
            } else {
                isScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const isMedia = () => {
        setMediaMenu((e) => !e);
    };

    return (
        <HeaderContainer>
            <img className="header_topCorner" src={ICONS.topCorner} alt="" />
            <div
                className="header_fixed"
                style={{
                    backgroundColor: scroll
                        ? "rgba(0, 0, 0, 0.7)"
                        : "transparent",
                }}
            >
                <div className="header_logoBx" onClick={() => navigation("/")}>
                    <img
                        className="header_oxygen1"
                        src={ICONS.oxygen1}
                        alt=""
                    />
                    <img
                        className="header_oxygen2"
                        src={ICONS.oxygen2}
                        alt=""
                    />
                    <img
                        className="header_oxygen"
                        src={ICONS.oxygenLarge}
                        alt=""
                    />
                </div>
                <div className="header_category">
                    <a href="#tickets">Абонементы</a>
                    <a href="#location">Местоположение</a>
                    <a href="#review">Обзор</a>
                </div>
                <a href="#contact" className="media_btn">
                    <Button children={"Связаться с нами"} />
                </a>
                <div className="header_mediaMenu" onClick={isMedia}>
                    <IoMdMenu
                        size={40}
                        color={COLORS.white}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div
                    className="header_media"
                    style={{
                        right: mediaMenu ? "0" : "-200%",
                    }}
                >
                    <div className="header_mediaClose" onClick={isMedia}>
                        <IoMdClose
                            size={40}
                            color={COLORS.white}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                    <div
                        className="headerMedia_logoBx"
                        onClick={() => navigation("/")}
                    >
                        <img
                            className="headerMedia_oxygen1"
                            src={ICONS.oxygen1}
                            alt=""
                        />
                        <img
                            className="headerMedia_oxygen2"
                            src={ICONS.oxygen2}
                            alt=""
                        />
                        <img
                            className="headerMedia_oxygen"
                            src={ICONS.oxygenLarge}
                            alt=""
                        />
                    </div>
                    <div className="headerMedia_category">
                        <a href="#tickets" onClick={isMedia}>
                            Абонементы
                        </a>
                        <a href="#location" onClick={isMedia}>
                            Местоположение
                        </a>
                        <a href="#review" onClick={isMedia}>
                            Обзор
                        </a>
                    </div>
                    <a href="#contact" onClick={isMedia}>
                        <Button
                            margin="15px 0 0 0"
                            children={"Связаться с нами"}
                        />
                    </a>
                </div>
            </div>
        </HeaderContainer>
    );
};

export default Header;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    .header_fixed {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        padding: 0 5%;
        position: fixed;
        z-index: 10;
        transition: 0.3s ease-in-out;
    }

    .header_mediaMenu {
        display: none;
    }
    .media_btn {
        display: block;
    }

    .header_topCorner {
        width: 250px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .header_logoBx {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        z-index: 2;

        img {
            object-fit: contain;
        }

        .header_oxygen1 {
            width: 70px;
            height: 40px;
        }
        .header_oxygen {
            width: 50px;
            height: 8px;
            position: relative;
            top: -5px;
        }
        .header_oxygen2 {
            width: 73px;
            height: 10px;
        }
    }

    .header_category {
        display: flex;
        align-items: center;
        gap: 35px;
        a {
            color: #fff;
            font-size: 16px;
            text-decoration: none;
            font-weight: 600;
        }
    }

    .header_media {
        position: fixed;
    }

    @media (max-width: 900px) {
        .header_category {
            display: none;
        }
        .media_btn {
            display: none;
        }

        .header_mediaMenu {
            display: flex;
        }

        .header_media {
            height: 100%;
            width: 80%;
            position: fixed;
            top: 0;
            z-index: 5;
            background-color: #000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: 0.4s ease;

            .header_mediaClose {
                position: absolute;
                left: 5%;
                top: 20px;
            }

            .headerMedia_logoBx {
                display: flex;
                flex-direction: column;
                position: relative;
                align-items: center;
                z-index: 2;

                img {
                    object-fit: contain;
                }

                .headerMedia_oxygen1 {
                    width: 70px;
                    height: 40px;
                }
                .headerMedia_oxygen {
                    width: 50px;
                    height: 8px;
                    position: relative;
                    top: -5px;
                }
                .headerMedia_oxygen2 {
                    width: 73px;
                    height: 10px;
                }
            }
            .headerMedia_category {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 15px;
                margin-top: 20px;

                a {
                    color: #fff;
                    font-size: 16px;
                    text-decoration: none;
                    font-weight: 600;
                }
            }
        }
    }
`;
