export const IMAGES = {
    welcome: require("../assets/images/welcome.png"),
    program1: require("../assets/images/program1.png"),
    program2: require("../assets/images/program2.png"),
    program3: require("../assets/images/program3.png"),
    program4: require("../assets/images/program4.png"),
    trainer1: require("../assets/images/trainer1.png"),
    trainer2: require("../assets/images/trainer2.png"),
    trainer3: require("../assets/images/trainer3.png"),
    trainer4: require("../assets/images/trainer4.png"),
    contact: require("../assets/images/contact.png"),
    review1: require("../assets/images/review1.jpeg"),
    review2: require("../assets/images/review2.jpg"),
    review3: require("../assets/images/review3.jpeg"),
    footer: require("../assets/images/footer.png"),
    video: require("../assets/images/OXYGYM Fitness Club.mp4"),
    video2: require("../assets/images/2024-01-24 18.38.59.mp4"),
    firGirl: require("../assets/images/fit-girl.webp"),
};
