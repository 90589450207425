import React from "react";
import { styled } from "styled-components";
import Button from "../../components/Button/Button";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Location from "../../components/Location/Location";
import MeetTrainers from "../../components/MeetTrainers/MeetTrainers";
import MembershipProgram from "../../components/MembershipProgram/MembershipProgram";
import MembershipVideo from "../../components/MembershipVideo/MembershipVideo";
import Text from "../../components/Text/Text";
import Welcome from "../../components/Welcome/Welcome";
import Winning from "../../components/Winning/Winning";

const HomeView = () => {
    return (
        <HomeComponent>
            <Welcome />
            <MembershipVideo />
            <MembershipProgram />
            {/* <MeetTrainers /> */}
            <Winning />
            <Contact />
            <Location />
            <Footer />
        </HomeComponent>
    );
};

export default HomeView;

const HomeComponent = styled.div`
    width: 100%;
    background-color: #000;
`;
