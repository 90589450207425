import React, { useEffect, useRef, useState } from "react";
import { IoLogoInstagram } from "react-icons/io";
import { RiTelegramLine } from "react-icons/ri";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { ICONS } from "../../utils/requireIcons";
import { IMAGES } from "../../utils/requireImages";
import Text from "../Text/Text";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { ReviewData } from "../../utils/db";

const Winning = () => {
    const [carousel, setCarousel] = useState(0);
    const [isAv, setIsAv] = useState(0);
    const [width, setWidth] = useState(0);

    const cardRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (cardRef.current) {
            setWidth(cardRef.current?.clientWidth);
        }
    }, []);

    const onCarousel = () => {
        if (isAv < 2) {
            setCarousel(carousel - width);
            setIsAv(isAv + 1);
        }
    };
    const onSetCarousel = () => {
        if (isAv != 0) {
            setCarousel(carousel + width);
            setIsAv(isAv - 1);
        }
    };

    return (
        <WinningContainer id="comments">
            <div className="Winning_title">
                <img className="Winning_line" src={ICONS.lineSmall} alt="" />
                {/* <Text children="Победа" fontSize="fourty" /> */}
                <Text
                    children="Отзывы"
                    fontSize="fourty"
                    color={COLORS.yellow}
                    margin="0 0 0 15px"
                />
            </div>
            <div className="winning_carousel">
                {ReviewData.map((e, index) => {
                    return (
                        <div
                            ref={cardRef}
                            key={index}
                            className="Winning_content"
                            style={{ transform: `translateX(${carousel}px)` }}
                        >
                            <div className="Winning_contentLeft">
                                <div className="Winning_contentImgBx">
                                    <img
                                        className="Winning_contentImg"
                                        src={e.image}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="Winning_info">
                                <Text
                                    children={e.comment}
                                    fontSize="sixteen"
                                    fontWeight="500"
                                    lineHeight="26px"
                                />
                                <div className="Winning_infoBottom">
                                    <Text
                                        children={e.name}
                                        fontSize="sixteen"
                                        margin="10px 0 0 0"
                                    />
                                    <Text
                                        children={e.job}
                                        fontSize="fourteen"
                                        fontWeight="400"
                                        margin="10px 0 0 0"
                                    />
                                    <div className="Winning_socialCard">
                                        <Text
                                            children={e.username}
                                            fontSize="fourteen"
                                            fontWeight="400"
                                        />
                                        <div className="Winning_socialBx">
                                            <a
                                                className="Winning_social"
                                                href={"#"}
                                            >
                                                <IoLogoInstagram
                                                    color="#fff"
                                                    size={24}
                                                />
                                            </a>
                                            <a
                                                className="Winning_social"
                                                href={"#"}
                                            >
                                                <RiTelegramLine
                                                    color="#fff"
                                                    size={24}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="Winning_arrow">
                    <span onClick={onSetCarousel}>
                        <MdKeyboardArrowLeft
                            className="arrowLeft"
                            color="#fff"
                            size={40}
                        />
                    </span>
                    <span onClick={onCarousel}>
                        <MdKeyboardArrowRight
                            className="arrowRight"
                            color="#fff"
                            size={40}
                        />
                    </span>
                </div>
            </div>
        </WinningContainer>
    );
};

export default Winning;

const WinningContainer = styled.div`
    padding: 50px 5%;

    .Winning_title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Winning_line {
            height: 40px;
            margin: 0 10px 0 0;
        }
    }

    .winning_carousel {
        display: flex;
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;

        .Winning_arrow {
            position: absolute;
            right: 20px;
            bottom: -10px;
            cursor: pointer;

            .arrowLeft {
                transition: 0.2s ease;
            }
            .arrowRight {
                transition: 0.2s ease;
            }
            .arrowLeft:hover {
                opacity: 0.5;
            }
            .arrowRight:hover {
                opacity: 0.5;
            }
        }
    }

    .Winning_content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        min-width: 100%;
        position: relative;
        top: 0;
        left: 0;
        transition: 0.3s ease;
        .Winning_contentLeft {
            width: 50%;

            .Winning_contentImgBx {
                border-radius: 7px;
                width: 320px;
                height: 320px;
                overflow: hidden;

                .Winning_contentImg {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }

        .Winning_info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40%;

            .Winning_socialCard {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 60%;

                .Winning_socialBx {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .Winning_content {
            .Winning_contentLeft {
                .Winning_contentImgBx {
                    width: 500px;
                }
            }
        }
    }
    @media (max-width: 767px) {
        .Winning_content {
            .Winning_contentLeft {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 30px;
            }
            .Winning_info {
                width: 100%;
            }
        }
    }
`;
