import React from "react";
import { styled } from "styled-components";
interface Props {
    value?: string;
    onChange: (e: any) => void;
    width?: string;
    height?: string;
}

const Input: React.FC<Props> = ({ onChange, value, height, width }) => {
    return (
        <InputContainer
            onChange={(e) => onChange(e.target.value)}
            value={value}
            style={{ width: width, height: height }}
        ></InputContainer>
    );
};

export default Input;

const InputContainer = styled.input`
    padding: 0px 10px;
    height: 35px;
    background-color: #1f1f1f;
    border-radius: 7px;
    outline: none;
    color: #fff;
    border: none;
    box-shadow: 0 2px 5px -2px rgba(144, 144, 144, 0.8);
`;
