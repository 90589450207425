import React from "react";
import { useNavigate } from "react-router";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { ICONS } from "../../utils/requireIcons";
import { IMAGES } from "../../utils/requireImages";
import Button from "../Button/Button";
import Header from "../Header/Header";
import Text from "../Text/Text";

const Welcome = () => {
    const navigation = useNavigate();
    // const backImg =
    return (
        <WelcomeContainer>
            <Header />
            <div className="welcome_backText">
                <img src={ICONS.oxygenNew} alt="" />
            </div>
            <img className="welcome_boyGirl" src={IMAGES.welcome} alt="" />
            <div className="welcome_textBox">
                <Text
                    children="Добро пожаловать в"
                    fontSize="sixty"
                    color={COLORS.white}
                    margin="0 10px 0 0"
                    // textAlign="center"
                />
                <Text
                    children="Oxygen Fitness Centre"
                    fontSize="sixty"
                    color={COLORS.yellow}
                    margin="0 10px 0 0"
                    // textAlign="center"
                />
                {/* <a href="#contact">
                    <Button margin="40px 0 0 0" children={"Связаться с нами"} />
                </a> */}
            </div>
        </WelcomeContainer>
    );
};

export default Welcome;

const WelcomeContainer = styled.div`
    height: 100vh;
    background-color: #1f1f1f;
    width: 100%;
    overflow: hidden;

    .welcome_backText {
        width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;

        img {
            width: 100%;
            object-fit: cover;
            height: 100vh;
        }
    }
    .welcome_boyGirl {
        position: absolute;
        right: 5%;
        bottom: 0px;
        width: 650px;
    }
    .welcome_textBox {
        position: absolute;
        bottom: 200px;
        left: 5%;
        z-index: 2;
    }

    @media (max-width: 767px) {
        .welcome_boyGirl {
            width: 320px;
            right: 0;
        }
        .welcome_textBox {
            bottom: 300px;
            left: 5%;
        }
        /* .welcome_backText {
            top: 38%;
            width: 100%;

            img {
                height: auto;
            }
        } */
    }
    @media (max-width: 992px) {
        .welcome_backText {
            top: 38%;
            width: 100%;
            img {
                height: auto;
            }
        }
    }
`;
