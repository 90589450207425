import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { GoDotFill } from "react-icons/go";

interface Props {
    title: string;
    type?: string;
    details: string[];
    details2: string[];
    sale?: string;
}

const SubsCard: React.FC<Props> = ({
    title,
    details,
    details2,
    type,
    sale,
}) => {
    return (
        <SubsCardContainer>
            <span className="saleBx">{sale}</span>
            <div className="detailsBox">
                <Text children={title} fontSize="tventy" />
                {details.map((e, index) => {
                    return (
                        <div className="details">
                            <GoDotFill color={COLORS.yellow} />
                            <Text
                                key={index}
                                children={e}
                                fontSize="eighteen"
                                fontWeight="400"
                            />
                        </div>
                    );
                })}
                <Text children={type} fontSize="tventy" margin="10px 0 0 0 " />
                {details2.map((e, index) => {
                    return (
                        <div className="details">
                            <GoDotFill color={COLORS.yellow} />
                            <Text
                                key={index}
                                children={e}
                                fontSize="eighteen"
                                fontWeight="400"
                            />
                        </div>
                    );
                })}
            </div>
            <a href="#contact">
                <Button
                    children={"Связаться с нами"}
                    margin="20px 0 0 0"
                    width="280px"
                    fontSize="20px"
                />
            </a>
        </SubsCardContainer>
    );
};

export default SubsCard;

const SubsCardContainer = styled.div`
    width: 49%;
    border: 1px solid #2e2d2d;
    padding: 20px 5%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 7px;
    align-items: center;
    gap: 6px;
    position: relative;

    .saleBx {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
        background-color: #f9c83f;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .detailsBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 100%;

        .details {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 10px;
        }
    }

    @media (max-width: 767px) {
        width: 100%;
    }
`;
