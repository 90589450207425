import React from "react";
import InputMask from "react-input-mask";
import "./MuskInput.css";

interface Props {
    value?: any;
    onChange?: (e: any) => void;
    width?: string;
    border?: string;
    borderRadius?: string;
}

const MaskInput: React.FC<Props> = ({
    value,
    onChange,
    width,
    border,
    borderRadius,
}) => {
    return (
        <InputMask
            className={"muskInput"}
            style={{
                width: width,
                border: border,
                borderRadius: borderRadius,
            }}
            mask="+ \9\98 99 999 99 99"
            maskChar=" "
            alwaysShowMask={true}
            value={value}
            onChange={onChange}
        ></InputMask>
    );
};

export default MaskInput;
