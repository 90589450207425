export const ICONS = {
    logo: require("../assets/icons/logo.png"),
    oxygen: require("../assets/icons/oxygen.png"),
    oxygenLarge: require("../assets/icons/oxygenLarge.png"),
    oxygenNew: require("../assets/icons/oxygenNew.png"),
    oxygen1: require("../assets/icons/oxygen1.png"),
    oxygen2: require("../assets/icons/oxygen2.png"),
    lineSmall: require("../assets/icons/lineSmall.png"),
    lineLarge: require("../assets/icons/lineLarge.png"),
    telegram: require("../assets/icons/telegram.png"),
    instagram: require("../assets/icons/instagram.png"),
    arrowLeft: require("../assets/icons/arrowLeft.png"),
    arrowRight: require("../assets/icons/arrowRight.png"),
    play: require("../assets/icons/play.png"),
    play1: require("../assets/icons/play1.png"),
    topCorner: require("../assets/icons/topCorner.png"),
};
