import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { SubsData } from "../../utils/db";
import { ICONS } from "../../utils/requireIcons";
import SubsCard from "../SubsCard/SubsCard";
import Text from "../Text/Text";

const MembershipProgram = () => {
    return (
        <MembershipProgramContainer id="tickets">
            <div className="Membership_title">
                <img className="Membership_line" src={ICONS.lineSmall} alt="" />
                <Text children="Наши" fontSize="fourty" />
                <Text
                    children="Абонементы"
                    fontSize="fourty"
                    color={COLORS.yellow}
                    margin="0 0 0 15px"
                />
            </div>
            <div className="Membership_cards">
                {SubsData.map((e, index) => {
                    return (
                        <SubsCard
                            key={index}
                            title={e.title}
                            type="Через день:"
                            sale={e.sale}
                            details={e.details}
                            details2={e.details2}
                        />
                    );
                })}
            </div>
        </MembershipProgramContainer>
    );
};

export default MembershipProgram;

const MembershipProgramContainer = styled.div`
    padding: 50px 5%;

    .Membership_title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Membership_line {
            height: 40px;
            margin: 0 10px 0 0;
        }
    }
    .Membership_text {
        width: 500px;
        margin: 20px 0 0 20px;
    }
    .Membership_cards {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin: 50px 0 0 0;
    }
    @media (max-width: 767px) {
        .Membership_text {
            width: 95%;
        }
    }
`;
