import React from "react";
import { styled } from "styled-components";
import styles from "./Text.module.css";

interface Props {
    children?: string;
    fontSize:
        | "tvelwe"
        | "fourteen"
        | "sixteen"
        | "eighteen"
        | "tventy"
        | "tventyFour"
        | "tventyEight"
        | "thirtyTwo"
        | "thirtySix"
        | "fourty"
        | "fourtySix"
        | "fifty"
        | "sixty";
    fontWeight?: "400" | "500" | "600";
    margin?: string;
    padding?: string;
    color?: string;
    cursor?: string;
    lineHeight?: string;
    textAlign?: any;
    textTransform?: any;
    writeSpace?: string;
}

const Text: React.FC<Props> = ({
    children,
    fontSize,
    fontWeight,
    color,
    margin,
    padding,
    cursor,
    lineHeight,
    textAlign,
    textTransform,
    writeSpace,
}) => {
    return (
        <TextComponent
            className={`${styles[fontSize]}`}
            style={{
                fontWeight: `${fontWeight}`,
                color: color,
                margin: margin,
                padding: padding,
                cursor: cursor,
                lineHeight: lineHeight,
                textAlign: textAlign,
                textTransform: textTransform,
                whiteSpace: writeSpace,
            }}
        >
            {children}
        </TextComponent>
    );
};

export default Text;

const TextComponent = styled.div`
    font-weight: 600;
    color: #fff;
`;
