import {
    MembershipVideoType,
    ReviewType,
    SubsType,
    TrainersType,
} from "../types/dbTypes";
import { IMAGES } from "./requireImages";

export const SubsData: SubsType[] = [
    {
        title: "Мужчинам",
        sale: "-40%",
        details: [
            "Абонемент на 1 - месяц: 600 000 сум",
            "Абонемент на 3 - мес. вип: 1 200 000 сум",
            "Абонемент на б - мес. вип: 2 000 000 сум",
            "Абонемент на 12 - мес. вип: 3 000 000 сум",
        ],
        details2: [
            "Абонемент 12 трен. на 1 - мес: 400 000 сум",
            "Абонемент 36 трен. на 3 - мес: 900 000 сум",
            "Абонемент 72 трен. на 6 - мес: 1 800 000 сум",
        ],
    },
    {
        title: "Женщинам",
        sale: "-40%",
        details: [
            "Абонемент на 1 - месяц: 400 000 сум",
            "Абонемент на 3 - мес. вип: 900 000 сум",
            "Абонемент на б - мес. вип: 1 700 000 сум",
            "Абонемент на 12 - мес. вип: 2 500 000 сум",
        ],
        details2: [
            "Абонемент 12 трен. на 1 - мес: 300 000 сум",
            "Абонемент 36 трен. на 3 - мес: 700 000 сум",
            "Абонемент 72 трен. на 6 - мес: 1 500 000 сум",
        ],
    },
];

export const TrainersData: TrainersType[] = [
    {
        name: "Lina Bob",
        image: IMAGES.trainer1,
        job: "Тренер",
        username: "@lina",
        instaLink: "#",
        tgLink: "#",
    },
    {
        name: "Alsu Vershinina",
        image: IMAGES.trainer2,
        job: "Тренер",
        username: "@alsu",
        instaLink: "#",
        tgLink: "#",
    },
    {
        name: "Jon Tyson",
        image: IMAGES.trainer3,
        job: "Тренер",
        username: "@jon",
        instaLink: "#",
        tgLink: "#",
    },
    {
        name: "Michael  Baccin",
        image: IMAGES.trainer4,
        job: "Тренер",
        username: "@michael",
        instaLink: "#",
        tgLink: "#",
    },
];

export const MembershipVideoData: MembershipVideoType[] = [
    {
        video: "",
        image: IMAGES.program1,
        title: "30-минутная домашняя тренировка для сжигания жира для начинающих.",
        type: "Проект тела",
        views: "50 миллионов просмотров 9 лет назад",
    },
    {
        video: "",
        image: IMAGES.program2,
        title: "Избавьтесь от упорного жира в верхней и нижней части живота..",
        type: "Тело сейчас",
        views: "8,9 тыс. просмотров 3 дня назад",
    },
    {
        video: "",
        image: IMAGES.program3,
        title: "30-минутная кардио-тренировка при ходьбе | Интенсивное все тело..",
        type: "Эми Вонг",
        views: "21 млн просмотров 1 год назад",
    },
    {
        video: "",
        image: IMAGES.program4,
        title: "30-минутная кардио-тренировка при ходьбе | Интенсивное все тело..",
        type: "Эми Вонг",
        views: "21 млн просмотров 1 год назад",
    },
];

export const ReviewData: ReviewType[] = [
    {
        name: "Антон Медведов",
        image: IMAGES.review2,
        job: "Спортсмен",
        comment:
            "Я решил начать заниматься бодибилдингом с программы Oxy Gen. Моими детскими интересами были бокс и футбол. Однако футбол совершенно не похож на бокс, бодибилдинг был мне незнаком. Но потом мне понравилось: занятия проводятся в зале. Всегда с интересом участвую в тренировках",
        username: "@anton.04",
        instaLink: "#",
        tgLink: "#",
    },
    {
        name: "Алсу Вершинина",
        image: IMAGES.review1,
        job: "Ютубер",
        comment:
            "Тогда я решил заняться боксом Oxy Gen. программа занятий, мой единственный опыт работы с нанесение ударов было во время занятий каратэ много лет назад. Но каратэ совсем другое чем бокс. К тому же, я никогда не занимался кикбоксингом. занятия в тренажерном зале. Я всегда придерживался ступенчатых занятий",
        username: "@lina",
        instaLink: "#",
        tgLink: "#",
    },
    {
        name: "Александр Грегорян",
        image: IMAGES.review3,
        job: "Спортсмен",
        comment:
            "Хороший спорт зал. Новое оборудование. Приветливый персонал. Чисто. Удобно что снизу есть автомойка.",
        username: "@alex",
        instaLink: "#",
        tgLink: "#",
    },
];
