import React from "react";
import { Route, Routes } from "react-router";
import HomeView from "../screens/home/HomeView";

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<HomeView />} />
        </Routes>
    );
};

export default Router;
