import React, { useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { MembershipVideoData } from "../../utils/db";
import { ICONS } from "../../utils/requireIcons";
import { IMAGES } from "../../utils/requireImages";
import ProgramVideo from "../ProgramVideo/ProgramVideo";
import Text from "../Text/Text";

const MembershipVideo = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayOpacity, setIsPlayOpacity] = useState(1);

    function isPlayVid() {
        if (!isPlaying) {
            videoRef.current?.play();
            setIsPlaying(true);
            setTimeout(() => {
                setIsPlayOpacity(0);
            }, 600);
        }
        if (isPlaying) {
            videoRef.current?.pause();
            setIsPlaying(false);
            setIsPlayOpacity(1);
        }
    }

    return (
        <MembershipVideoContainer id="review">
            <div className="Membership_title">
                <img className="Membership_line" src={ICONS.lineSmall} alt="" />
                <Text children="Обзор" fontSize="fourty" />
                <Text
                    children="Фитнес Клуба"
                    fontSize="fourty"
                    color={COLORS.yellow}
                    margin="0 0 0 15px"
                />
            </div>
            <div className="video_content">
                <div className="Membership_videos">
                    {/* <video
                        src={IMAGES.video}
                        // poster={IMAGES.program3}
                        // ref={videoRef}
                        // onClick={isPlayVid}
                        autoPlay={true}
                    ></video> */}
                    <video
                        loop={true}
                        muted={true}
                        autoPlay={true}
                        playsInline={true}
                    >
                        <source src={IMAGES.video2} />
                    </video>
                </div>
            </div>
        </MembershipVideoContainer>
    );
};

export default MembershipVideo;

const MembershipVideoContainer = styled.div`
    padding: 50px 5%;

    .Membership_title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Membership_line {
            height: 40px;
            margin: 0 10px 0 0;
        }
    }

    .Membership_videos {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        border-radius: 10px;
        overflow: hidden;
        height: 550px;
        position: relative;
        margin-top: 30px;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @media (max-width: 767px) {
        .Membership_text {
            width: 95%;
        }
        .Membership_videos {
            height: 350px;
        }
    }
`;
